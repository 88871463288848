<template>
    <div class="site-logo">

        <NuxtLink to="/" class="site-logo__link" v-if="logo">
            <picture class="site-logo__image">
                <NuxtImg provider="kontentAiProvider" :src="logo" alt="ADDI Logo">
                </NuxtImg>
            </picture>
        </NuxtLink>
    </div>
</template>
<script setup lang="ts">
const logo = ref('');
const siteStore = useSiteStore()
logo.value = siteStore?.site?.elements?.websiteLogo?.value[0]?.url;
</script>