<template>
    <footer class="global-footer">
        <div class="container container--100">
            <div class="container__col container__col--100">
                <div class="global-footer__top">
                    <div class="social-links">
                        <div class="social-links__title">Follow {{ siteShortName }}</div>
                        <div class="social-links__links">
                            <ul>
                                <li v-for="link in siteStore.socialLinks" :key="link.system.id">
                                    <NuxtLink :class="link.elements.iconClass.value" :to="link.elements.url.value"
                                        target="_blank">
                                        <NuxtImg provider="kontentAiProvider"
                                            v-for="(item, index) in link?.elements?.iconImage?.value" :src="item?.url"
                                            :alt="link.elements.title.value"></NuxtImg>
                                    </NuxtLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <nav class="footer-nav" aria-label="footer navigation">
                        <div class="footer-nav__section">
                            <div class="footer-nav__col-wrapper">
                                <ul class="footer-nav__col">
                                    <li v-for="(item, index) in navigationItems" :key="index">
                                        <NuxtLink :to="item.url">{{ item.title }}</NuxtLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div class="contact-address">
                        <span class="contact-address__phone">
                        </span>
                    </div>
                </div>
                <div class="global-footer__middle" id="cookieConsent">
                    <div id="consent-banner"></div>
                    <div id="teconsent">
                    </div>
                </div>
                <div class="global-footer__bottom">
                    <div class="global-footer__logo-wrapper">
                        <div class="site-logo">
                            <span class="site-logo__title">
                                {{ siteName }}
                            </span>
                        </div>

                    </div>
                    <div class="global-footer__links-copyright-wrapper">
                        <nav class="footer-links">
                            <ul>
                                <li>
                                    <NuxtLink to="/privacy">Privacy</NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink to="/terms">Terms</NuxtLink>
                                </li>
                            </ul>
                        </nav>
                        <div class="copyright">
                            <span class="copyright__symbol">©</span>
                            <span class="copyright__notice">{{ siteName }} {{ year }}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import type { NavigationItem } from '~/types/NavigationItem';

const config = useRuntimeConfig()
const navigationItems = ref<NavigationItem[]>([])
const siteStore = useSiteStore()
await siteStore.fetchData()
const siteName = siteStore.site.elements.siteName.value
const siteShortName = siteStore.site.elements.siteShortName.value
const year = new Date().getFullYear()
navigationItems.value = siteStore.getFooterNavigationItems()

onMounted(() => {
    if (process.client && config.public.isProduction) {
        loadScript('https://consent.trustarc.com/v2/notice/rnygux', 'teconsent')
    }
})

function loadScript(url: string, appendToElementId: string, async = true, type = 'text/javascript') {
    // Create script element
    const script = document.createElement('script');
    script.type = type; // Setting script type, default is 'text/javascript'
    script.async = async; // Should the script be loaded asynchronously? Default is true
    script.src = url; // The URL of the script to load
    script.referrerPolicy = 'origin'

    // Optional: Add event listeners for load and error
    script.onload = () => {
        //console.log('Script loaded successfully:', url);
    };
    script.onerror = (error) => {
        console.error('Error loading script:', url, error);
    };

    // Append the script to the document's head (or you can choose to append it to the body)
    document.getElementById(appendToElementId)?.appendChild(script);
}


</script>
<style lang="scss" scoped>
@use "~/assets/scss/footer-links.scss";

.footer-nav {
    ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}
</style>